# Object methods

Object.defineProperty Object.prototype, 'count', value: (callbackFn = ((item) => item), initialValue = 0) ->
  @reduce ((sum, value) => count + (if callbackFn(value) then 1 else 0)), initialValue

Object.defineProperty Object.prototype, 'sum', value: (callbackFn = ((item) => item), initialValue = 0) ->
  @reduce ((sum, value) => sum + callbackFn(value)), initialValue

Object.defineProperty Object.prototype, 'some', value: (callbackFn, thisArg) ->
  return true for key, value of @ when callbackFn.call thisArg, value, key, @
  false
  # Object.values(@).some callbackFn, thisArg

Object.defineProperty Object.prototype, 'every', value: (callbackFn, thisArg) ->
  return false for key, value of @ when !callbackFn.call thisArg, value, key, @
  true
  # Object.values(@).every callbackFn, thisArg

Object.defineProperty Object.prototype, 'find', value: (callbackFn, thisArg) ->
  return value for key, value of @ when callbackFn.call thisArg, value, key, @
  undefined
  # Object.values(@).find callbackFn, thisArg

Object.defineProperty Object.prototype, 'forEach', value: (callbackFn, thisArg) ->
  callbackFn.call thisArg, @[key], key, @ for key, value of @
  undefined
  # Object.entries(@).forEach ([key, value]) => callbackFn.call thisArg, value, key, @

Object.defineProperty Object.prototype, 'filter', value: (callbackFn, thisArg) ->
  result = {}
  result[key] = value for key, value of @ when callbackFn.call thisArg, value, key, @
  result
  # return Object.fromEntries Object.entries(@).filter ([key, value]) => callbackFn.call thisArg, value, key, @

Object.defineProperty Object.prototype, 'map', value: (callbackFn, thisArg) ->
  result = {}
  result[key] = callbackFn.call thisArg, value, key, @ for key, value of @
  result
  # return Object.fromEntries Object.entries(@).map ([key, value]) => [key, callbackFn.call thisArg, value, key, @]

Object.defineProperty Object.prototype, 'reduce', value: (callbackFn, initialValue) ->
  initialValue = callbackFn initialValue, value, key, @ for key, value of @
  initialValue
  # return Object.entries(@).reduce (result, [key, value]) => callbackFn(result, value, key, @); initialValue

Object.defineProperty Object.prototype, 'mapEntries', value: (callbackFn, thisArg) ->
  result = {}
  Object.assign result, callbackFn.call thisArg, value, key, @ for key, value of @
  result
  # Object.fromEntries Object.entries(@).map ([key, value]) => Object.entries(callbackFn.call thisArg, value, key, @)[0]

Object.defineProperty Object, 'camelizeEntries', value: (object) ->
  if Array.isArray(object) then object.map (value) => Object.camelizeEntries(value)
  else if object instanceof Object then object.mapEntries (value, key) => {[key.camelize()]: Object.camelizeEntries(value)}
  else object
