# Array methods

Object.defineProperty Array.prototype, 'compact', value: () ->
  @filter (element) => element?
  # item for item, index in @ when item

Object.defineProperty Array.prototype, 'shuffle', value: () ->
  for currentIndex in [@length - 1 ... 0] by -1
    randomIndex = Math.floor(Math.random() * (currentIndex + 1))
    [@[currentIndex], @[randomIndex]] = [@[randomIndex], @[currentIndex]]
  @

Object.defineProperty Array.prototype, 'count', value: (callbackFn = ((item) => item), initialValue = 0) ->
  @reduce ((count, element) => count + (if callbackFn(element) then 1 else 0)), initialValue
  # initialValue += (if callbackFn(item) then 1 else 0) for item, index in @
  # initialValue

Object.defineProperty Array.prototype, 'sum', value: (callbackFn = ((item) => item), initialValue = 0) ->
  @reduce ((sum, element) => sum + callbackFn(element)), initialValue
  # initialValue += callbackFn(item) for item, index in @
  # initialValue

Object.defineProperty Array.prototype, 'min', value: (callbackFn = ((item) => item), initialValue = 0) ->
  @reduce ((min, element) => Math.min(min, callbackFn(element))), initialValue
  # initialValue = Math.min callbackFn(item), initialValue for item, index in @
  # initialValue

Object.defineProperty Array.prototype, 'max', value: (callbackFn = ((item) => item), initialValue = 0) ->
  @reduce ((max, element, index, array) => Math.max(max, callbackFn element, array)), initialValue
  # initialValue = Math.max callbackFn(item, index, @), initialValue for item, index in @
  # initialValue

Object.defineProperty Array.prototype, 'group', value: (callbackFn, thisArg) ->
  @reduce(((result, item, index, array) =>
    key = callbackFn.call(thisArg, item, index, array)
    if key in result then result[key].push(item) else result[key] = [item]
    result), {})

Object.defineProperty(Array.prototype, 'groupToMap',
  value: (callbackFn, thisArg) ->
    @reduce(((result, item, index, array) =>
      key = callbackFn.call(thisArg, item, index, array)
      if result.has(key) then result.get(key).push(item) else result.set(key, [item])
      result), new Map()))
