# String methods

Object.defineProperty String.prototype, 'capitalize', value: -> @.charAt(0).toUpperCase() + @.slice(1)

Object.defineProperty String.prototype, 'titleize', value: -> @.split('_').map((value) -> value.capitalize()).join(' ')

Object.defineProperty String.prototype, 'camelize', value: -> @.split('_').map((value, index) -> if index then value.capitalize() else value).join('')

Object.defineProperty String.prototype, 'compare', value: (string) ->
  splitNumbers = /[^0-9]+|[0-9]+/g; splitThis = this.match(splitNumbers); splitString = string.match(splitNumbers)
  index = splitThis.findIndex((string, index) => string.localeCompare(splitString[index]))
  if index == -1 then 0 else if index % 2 then splitThis[index] - splitString[index] else splitThis[index].localeCompare(splitString[index])
